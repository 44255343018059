import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import store from './store';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

React.icons = icons;
const onRedirectCallback = (appState) => {

  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};



ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    //audience={config.audience}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    responseType="token id_token"
  >
  {/* <Auth> */}
  <Provider store={store}>
    <App />
  </Provider>
 {/* </Auth>, */}
  </Auth0Provider>, 
document.getElementById('root')
);

  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
