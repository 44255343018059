import axios from 'axios';

import { toast } from 'react-toastify';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 120000;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.validateStatus = (status) => status >= 200 && status < 300;

const instance = axios.create();

const handleErrors = (error) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Error Logging :: ', error.message, JSON.stringify(error));
  }
  if (error.message) {
    if (error.message === 'Network Error') {
      toast.error('No internet connection. Please check your internet settings')
      return Promise.reject(error);
    }
  }
  if (!error.response) {
    if (error.code === 'ECONNABORTED') {
      toast.error('Request Timeout');
    }
    return Promise.reject(error);
  }
  if (error.response) {
    let errorMessage = 'Something went wrong.';
    switch (error.response.status) {
      case (400):
        //notify.show(errorMessage, 'error');
        break;
      case (401):
        localStorage.clear()
        window.history.pushState({},'','/login')
        window.location.reload()
        break;
      // case (403):
      //   return Promise.reject(error);
      // case (500):
      //   notify.show(error.message || errorMessage, 'error');
      //   break;
      default:
        //notify.show(error.message || errorMessage, 'error');
        break;
    }
  }
  return Promise.reject(error);
};

const setAuthHeadersInInterceptors = (config) => {
  if ((localStorage.getItem('accessToken'))) {
    config.headers.common['Authorization'] =  `Bearer ${localStorage.getItem('accessToken')}` || '';
    //config.headers.common['Authorization'] =  ""
  }
  return config;
}

export const setupInterceptor = () => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => handleErrors(error),
  );

  instance.interceptors.request.use(
    (config) => setAuthHeadersInInterceptors(config),
    (error) => Promise.reject(error),
  );
};

export default instance;